<template>
  <a href="javascript:void(0)" v-on:click="playItem">
    <div :class="{'bg-gray-200': songIndex === index}" class="flex items-center space-x-4 py-3 hover:bg-gray-100 rounded-md -mx-2 px-2">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.artists && song.artists.data && song.artists.data[0] && song.artists.data[0].avatar ? song.artists.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </div>
      <div class="flex-1">
        <div class="text-lg font-semibold">
         {{ song.title }}
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="song.artists && song.artists.data">
          <PeopleOfSong :people="song.artists.data" type="artist"/>
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="song.user">
          <user-of-song :people="[song.user]"/>
        </div>
        <div class="text-sm text-gray-500 mt-1" :title="thousandFormat(song.views) + ' lượt nghe'">
          <i class="uil uil-headphones"></i> {{ numberFormat(song.views) }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import PeopleOfSong from "./song/PeopleOfSong";
import UserOfSong from "./song/UserOfSong";
import {
  numberFormat,
  thousandFormat
} from "../core/services/utils.service";

export default {
  name: "SongItemPlaylist",
  props: {
    index: Number,
    songIndex: Number,
    song: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  components: {
    PeopleOfSong, UserOfSong
  },
  methods: {
    playItem() {
      this.$emit("playItem", this.index);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  }
}
</script>
