<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-3/5 space-y-7">
      <div class="card">
        <img :alt="tag.name"
             :src="tag.thumbnail ? tag.thumbnail.url : 'https://static.bcdcnt.net/assets/images/bcdcnt-bg.png'"
             class="object-cover"/>

        <div class="p-3 md:flex items-center justify-between">
          <div>
            <h1 class="text-2xl font-semibold">
              <router-link v-if="tag.slug" :to="{name: 'TagDetail', params: {name: tag.slug}}">
                <span class="keyword px-2">
                  <span class="keyword-text">
                    {{ tag.name }}
                  </span>
                </span>
              </router-link>
            </h1>
          </div>
        </div>
      </div>

      <div class="card p-3 mt-4 playlist-frame">
        <div class="grid divide-gray-200 gap-x-4 mt-4"
             v-if="tag.items && tag.items.length">
          <template v-for="(song, index) in tag.items" :key="'song' + index">
            <song-item-playlist :song-index="songIndex" v-on:playItem="handlePlayItem"
                                :song="song" :index="index"/>
          </template>
        </div>
      </div>

      <div class="mt-4">
        <comment object-type="tag" :comment-id="commentId" :object-id="id" field="id" type="ID"/>
      </div>
    </div>

    <div class="lg:w-2/5 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>

      <right/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  changePageTitle,
  getSongFieldsByType,
  getPluralFromSongPrefix,
  getUploadTypeText,
  logActivity,
  numberFormat,
  thousandFormat,
  timeago
} from "../../core/services/utils.service";
import SongItemPlaylist from "../SongItemPlaylist";
import Right from "../right/Right";
import Comment from "../comment/Comment";
import LatestComment from "../comment/LatestComment";

export default {
  name: "TagListen",
  components: {
    LatestComment,
    SongItemPlaylist,
    Right,
    Comment
  },
  data() {
    return {
      id: null,
      commentId: null,
      tag: {
        id: 0,
        name: " ",
        slug: "",
        items: []
      },
      prefix:"",
      songIndex: 0,
      sort: "views:DESC",
      sortColumn: "views",
      sortDirection: "DESC",
      songs: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 0
        }
      },
    }
  },
  created() {
    window.addEventListener("message", (event) => {
      if (event.data.action === "songIndex") {
        this.songIndex = event.data.value;
      }
    }, false);
  },
  methods: {
    loadTag() {
      var type = getPluralFromSongPrefix(this.prefix);

      let query = `query($slug: String!) {
        tag(slug: $slug) {
          id
          name
          slug
        }
      }`;

      ApiService.graphql(query, {slug: this.tag.name})
        .then(({ data }) => {
          if (data.data && data.data.tag) {
            this.tag = data.data.tag;
            this.id = this.tag.id;
            this.loadSongs();
            changePageTitle("Tag: " + this.tag.name + " - nghe " + getUploadTypeText(type).toLowerCase());
            logActivity("listen", "tag", this.tag.id);
          } else {
            this.tag = null;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadSongs() {
      var type = getPluralFromSongPrefix(this.prefix);
      let songFields = getSongFieldsByType(type);
      let name = type + "s" + "ByTag";

      let query = `query($tag: String!, $page: Int, $orderBy: [OrderByClause!]) {
        ${name}(first: 1000, page: $page, tag: $tag, orderBy: $orderBy) {
          data {
            id
            title
            slug
            views
            file_type
            thumbnail {
              url
            }
            file {
              audio_url
            }
            ${songFields}
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, { tag: this.tag.name, page: this.songs.paginatorInfo.currentPage, orderBy: this.getOrderBy() })
        .then(({ data }) => {
          if (data.data && data.data[name] && data.data[name].data && data.data[name].data.length) {
            data.data[name].data.map((item) => {
              item.prefix = this.prefix;
            });
            this.tag.items = data.data[name].data;
            this.$emit("playPlaylist", this.tag.items, "playlist");
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    getOrderBy() {
      return [{column: this.sortColumn, order: this.sortDirection}];
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    timeago(x) {
      return timeago(x);
    },
    handlePlayItem(index) {
      this.$emit("playItem", index);
    }
  },
  watch: {
    sort: {
      handler(sort) {
        let tmp = sort.split(":");
        this.sortColumn = tmp[0];
        this.sortDirection = tmp[1];
        this.loadTag();
      }
    },
  },
  mounted() {
    this.commentId = this.$route.query.comment_id;
    this.tag.name = this.$route.params.name;
    this.prefix = this.$route.query.type;
    this.loadTag();
  },
  beforeRouteUpdate(to, from, next) {
    this.tag.name = this.$route.params.name;
    this.prefix = this.$route.query.type;
    window.scrollTo(0, 0);
    next();
    this.loadTag();
  },
}
</script>
